<template>
    <div id="app">
        <GChart></GChart>
        <div v-if="currentUser == null">
            <Login />
        </div>
        <div v-if="currentUser != null">
            <b-navbar id="topnavbar" toggleable="lg" type="dark" variant="info" class="is_sticky">
                <b-navbar-brand href="#"><img src="images/logo_dagway.png" /></b-navbar-brand>

                <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

                <b-collapse id="nav-collapse" is-nav>
                    <b-navbar-nav>
                        <!-- b-nav-item href="#" v-on:click="switch_page('home')" :class="getActive('home')">Tableau de bord</!--b-nav-item -->
                        <b-nav-item href="#" v-on:click="switch_page('variations')" :class="getActive('variations')">Variations</b-nav-item>
                        <!-- b-nav-item href="#">Statistiques</!--b-nav-item -->
                        <b-nav-item href="#" v-on:click="switch_page('catalogue')" :class="getActive('catalogue')">Catalogue</b-nav-item>
                        <b-nav-item href="#" v-show="tabgroupLength > 0" v-on:click="switch_page('detail')" :class="getActive('detail')">Detail ({{tabgroupLength}})</b-nav-item>
                    </b-navbar-nav>

                    <!-- Right aligned nav items -->
                    <b-navbar-nav class="ml-auto">

                        <b-nav-item-dropdown text="Paramètrage" right>
                            <b-nav-text>Préférences</b-nav-text>
                            <b-dropdown-item href="#" v-on:click="switch_page('surveillance')" :class="getActive('catalogue')">Surveillance</b-dropdown-item>
                            <b-nav-text>Taxomonie</b-nav-text>
                            <b-dropdown-item href="#" v-on:click="switch_page('zonegeo')">Zone géo</b-dropdown-item>
                            <b-dropdown-item href="#" v-on:click="switch_page('typeproduct')">Types de produit</b-dropdown-item>
                            <b-dropdown-item href="#" v-on:click="switch_page('levelproduct')">Niveau</b-dropdown-item>
                            <b-dropdown-item href="#" v-on:click="switch_page('listduration')">Durées</b-dropdown-item>
                            <b-dropdown-item href="#" v-on:click="switch_page('groupproduct')">Chaines de comparaisons</b-dropdown-item>
                            <template v-if="currentUser.userTypeId == 1">
                                <b-nav-text>Produits</b-nav-text>
                                <b-dropdown-item href="#" v-on:click="switch_page('listallproducts')">Éditer les Produits</b-dropdown-item>
                                <b-nav-text>Administration</b-nav-text>
                                <b-dropdown-item href="#" v-on:click="switch_page('listuser')">Gestion des utilisateurs</b-dropdown-item>
                            </template>
                        </b-nav-item-dropdown>

                        <b-nav-item-dropdown right>
                            <!-- Using 'button-content' slot -->
                            <template #button-content>
                                <em>{{ fullName}}</em>
                            </template>
                            <b-dropdown-item href="#" v-on:click="logout">D&eacute;connexion</b-dropdown-item>
                        </b-nav-item-dropdown>
                    </b-navbar-nav>
                </b-collapse>
            </b-navbar>
            <div id="page_content">
                <Home v-bind:appUser="appUser" v-show="CurrentPage=='home'" />
                <Variations v-show="CurrentPage=='variations'"></Variations>
                <Catalogue v-show="CurrentPage=='catalogue'"></Catalogue>
                <TabsGroup v-show="CurrentPage=='detail'"></TabsGroup>
                <Surveillance v-show="CurrentPage=='surveillance'"></Surveillance>
                <ZoneGeo v-show="CurrentPage=='zonegeo'"></ZoneGeo>
                <TypeProduct v-show="CurrentPage=='typeproduct'"></TypeProduct>
                <LevelProduct v-show="CurrentPage=='levelproduct'"></LevelProduct>
                <ListDuration v-show="CurrentPage=='listduration'"></ListDuration>
                <GroupProduct v-show="CurrentPage=='groupproduct'"></GroupProduct>
                <ListNewProducts v-show="CurrentPage=='listnewproducts'"></ListNewProducts>
                <ListUpdatedProducts v-show="CurrentPage=='listupdatedproducts'"></ListUpdatedProducts>
                <ListAllProducts v-show="CurrentPage=='listallproducts'"></ListAllProducts>
                <template v-if="currentUser.userTypeId == 1">
                    <ListUsers v-show="CurrentPage=='listuser'"></ListUsers>
                </template>
            </div>
        </div>
    </div>
</template>

<script>
    import "bootstrap/dist/css/bootstrap.css"
    import "bootstrap-vue/dist/bootstrap-vue.css"

    import Vue from 'vue'
    import axios from 'axios'
    import { mapState } from "vuex"
    import Login from './components/Login.vue'
    import Home from './components/Home.vue'
    import Catalogue from './components/Catalogue.vue'
    import TabsGroup from './components/TabsGroup.vue'
    import Surveillance from './components/Surveillance.vue'
    import ZoneGeo from './components/setup/ZoneGeo.vue'
    import TypeProduct from './components/setup/TypeProduct.vue'
    import LevelProduct from './components/setup/LevelProduct.vue'
    import ListDuration from './components/setup/ListDuration.vue'
    import GroupProduct from './components/setup/GroupProduct.vue'
    import ListUsers from './components/admin/ListUsers.vue'
    import Variations from './components/variations/Variations.vue'
    import ListNewProducts from './components/products/ListNewProducts.vue'
    import ListUpdatedProducts from './components/products/ListUpdatedProducts.vue'
    import ListAllProducts from './components/products/ListAllProducts.vue'
    import { GChart } from 'vue-google-charts'

    export default {
        name: 'App',
        components: {
            Login,
            Home,
            Catalogue,
            TabsGroup,
            Surveillance,
            GChart,
            ZoneGeo,
            TypeProduct,
            LevelProduct,
            ListDuration,
            GroupProduct,
            ListUsers,
            Variations,
            ListNewProducts,
            ListUpdatedProducts,
            ListAllProducts
        },
        data() {
            return {
                appUser: null
            }
        },
        methods: {
            switch_page: function (page) {
                this.$store.commit('setCurrentTab', page)
                return false
            },
            logout: function () {
                let self = this
                axios.post(this.$ipService + '/api/users/logout',
                    {
                        id: Vue.$cookies.get("session_id")
                    }
                ).then(function () {
                    Vue.$cookies.set("session_id", "")
                    self.$store.commit('setUser', null)
                    return
                })
                    .catch(function (error) {
                        console.log(error);
                    });
                return false;
            },
            getActive: function (data) {
                return (data == this.currentTab) ? "active" : ""
            },
            onResize() {
                this.$store.dispatch('updateWindowsSize')
            }
        },
        computed: {
            tabgroupLength: function () {
                if (this.tabsgroup == undefined) {
                    return 0;
                }
                return Object.keys(this.tabsgroup).length
            },
            CurrentPage: function () {
                if (this.currentTab == "") {
                    this.$store.commit('setCurrentTab', 'variations')
                    return 'variations'
                }
                return this.currentTab
            },
            fullName() { return this.$store.state.currentUser.profile.firstName + " " + this.$store.state.currentUser.profile.lastName },
            ...mapState(["currentUser", "webSites", "tabsgroup", "currentTab"])
        },
        mounted() {
            this.$nextTick(() => {
                window.addEventListener('resize', this.onResize);
            })
            this.onResize()
            this.$store.dispatch('loadWebsites')
            this.$store.dispatch('loadGroupdurations')
            this.$store.dispatch('loadGroupcountries')
            this.$store.dispatch('loadCountries')
            this.$store.dispatch('loadCountriesActives')
            this.$store.dispatch('loadGrouptypes')
            this.$store.dispatch('loadGrouplevels')
            this.$store.dispatch('loadGroupproducts')
            this.$store.dispatch('loadGroupsOrProducts')


            let self = this
            axios.post(this.$ipService + '/api/users/session',
                {
                    id: Vue.$cookies.get("session_id")
                }
            ).then(function (response) {
                if (response.data.id != null) {
                    Vue.$cookies.set("session_id", response.data.id)
                    self.$store.commit('setUser', response.data.user)
                    self.appUser = response.data.user;
                }
            })
                .catch(function (error) {
                    console.log(error);
                });

        }
    }
</script>

<style>
    #app {
        font-family: Avenir, Helvetica, Arial, sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        color: #2c3e50;
        margin-top: 0;
        font-size: 0.8em;
    }

    .navbar-text {
        color: #17a2b8 !important;
    }

    #topnavbar, #topnavbar .navbar-brand {
        padding-top: 0;
        padding-bottom: 0;
    }

    .w-20 {
        width: 20%;
    }


    .w-10 {
        width: 10%
    }

    .w-40 {
        width: 40%;
    }

    .w-90 {
        width: 90%;
    }

    .number_red {
        color: red;
    }

    .number_green {
        color: green;
    }

    .fixed-with {
        max-height: 500px;
        overflow-y: auto;
    }

    .fixed-with .custom-control-inline {
        width: 250px;
    }

    .navbar.is_sticky {
        position: fixed;
        top: 0;
        right: 0;
        left: 0;
        z-index: 1030;
        width: 100%;
        -webkit-animation: .95s ease-in-out 0s normal none 1 running fadeInDown;
        animation: .95s ease-in-out 0s normal none 1 running fadeInDown;
        -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
        transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
        -webkit-box-shadow: 0 8px 20px 0 rgba(0, 0, 0, 0.1);
        box-shadow: 0 8px 20px 0 rgba(0, 0, 0, 0.1);
    }

    #page_content {
        margin-top:36px;
    }
</style>