<template>
    <div>
        <b-tabs content-class="mt-3">
            <b-tab title="Mode de surveillance">
                <b-card title="">
                    <b-form-checkbox switch v-model="monitoring" @change="monitoringChange">Spécifique</b-form-checkbox>
                </b-card>
                <b-card title="Tous surveiller" v-show="!monitoring">
                    
                </b-card>
                <b-card title="Spécifique" v-show="monitoring">
                    <b-table :items="groupCountries" :fields="gCountriesFields" head-variant="light">
                        <template #cell(label)="data">
                            <b-form-checkbox switch @change="monitoredZonesChange(data.item.id, $event)" v-model="monitoredGCId[data.item.id]">{{ data.value }}</b-form-checkbox>
                        </template>
                    </b-table>
                    <b-table :items="groupTypes" :fields="gTypesFields" head-variant="light">
                        <template #cell(label)="data">
                            <b-form-checkbox switch @change="monitoredTypesChange(data.item.id, $event)" v-model="monitoredTPId[data.item.id]">{{ data.value }}</b-form-checkbox>
                        </template>
                    </b-table>
                </b-card>
            </b-tab>
            <b-tab title="Chaines">
                <loading :active.sync="isLoading"
                         :is-full-page="fullPage"></loading>
                <b-form-group label="Filter"
                              label-for="filter-input"
                              label-cols-sm="3"
                              label-align-sm="right"
                              label-size="sm"
                              class="mb-0">
                    <b-input-group size="sm">
                        <b-form-input v-model="filter"
                                      type="search"
                                      placeholder="Group Produit/Produit"></b-form-input>

                        <b-input-group-append>
                            <b-button :disabled="!filter" @click="filter = ''">Clear</b-button>
                        </b-input-group-append>
                    </b-input-group>
                </b-form-group>
                <b-form-group label="Filter"
                              label-for="filter-input"
                              label-cols-sm="3"
                              label-align-sm="right"
                              label-size="sm"
                              class="mb-0">
                    <b-input-group size="sm">
                        <b-form-select v-model="modeview">
                            <b-form-select-option :value="0">Voir tous les groupes</b-form-select-option>
                            <b-form-select-option :value="1">Voir uniquement les groupes surveillés</b-form-select-option>
                            <b-form-select-option :value="2">Voir uniquement les groupes non surveillés</b-form-select-option>
                        </b-form-select>
                    </b-input-group>
                </b-form-group>
                <b-pagination v-model="currentPage"
                              :total-rows="totalRows"
                              :per-page="perPage"
                              aria-controls="gpTable" v-show="!isLoading"></b-pagination>
                <b-table id="gpTable" :items="groupsProducts" :fields="fields" head-variant="light"
                         :per-page="perPage" v-show="!isLoading"
                         :current-page="currentPage">
                    <template #cell(adapters)="data">
                        <div v-for="adapter in data.value" :key="adapter.id"><span>{{adapter.name|upper}}</span>:<span>{{adapter.products.join(', ')}}</span></div>
                    </template>
                    <template #cell(comment)="data">
                        <span :inner-html.prop="data.value|ln2br" />
                    </template>
                    <template #cell(id)="data">
                        <span class="icon-span"><b-icon title="voir" icon="search" scale="1.5" v-on:click="addGroupProduct(data.item, $event)" @contextmenu="addGroupProduct(data.item, $event)" class="clickable"></b-icon></span>
                        <span class="icon-span">
                            <b-icon title="surveiller" icon="exclamation-triangle-fill" scale="1.5" v-show="excludeGCId[data.value]" class="clickable" @click="excludeChange(data.value)"></b-icon>
                            <b-icon title="sous surveillance" icon="clipboard-check" scale="1.5" v-show="!excludeGCId[data.value]" class="clickable" @click="excludeChange(data.value)"></b-icon>
                        </span>
                    </template>

                </b-table>
            </b-tab>
        </b-tabs>
    </div>
</template>

<script>
    import { mapState } from "vuex"
    import axios from 'axios'
    import Loading from 'vue-loading-overlay';
    // Import stylesheet
    import 'vue-loading-overlay/dist/vue-loading.css';
import { Object } from "core-js";

    export default {
        name: 'Surveillance',
        components: {
            Loading
        },
        data() {
            return {
                isLoading: false,
                fullPage: true,
                perPage: 5,
                currentPage: 1,
                modeview: 0,
                groupProducts: null,
                monitoring: false,
                oldmonitoring: false,
                oldmonitoredGCId: "",
                oldmonitoredTPId: "",
                oldexcludeGCId: "",

                monitoredGC: [],
                excludeGCId: {},
                monitoredGCId: {},
                monitoredTPId: {},
                fields: [
                    {
                        key: 'label',
                        label: 'Group Produit'
                    },
                    {
                        key: 'adapters',
                        label: 'Produits'
                    },
                    {
                        key: 'comment',
                        label: 'Commentaire'
                    },
                    {
                        key: 'id',
                        label: 'Action'
                    }],
                gCountriesFields: [
                    {
                        key: 'label',
                        label: 'Zone Géographique'
                    }],
                gTypesFields: [
                    {
                        key: 'label',
                        label: 'Type de produit'
                    }],
                filter: null,
            }
        },
        filters: {
            capitalize: function (value) {
                if (!value) return ''
                value = value.toString().toLowerCase()

                return value.charAt(0).toUpperCase() + value.slice(1)
            },
            upper: function (value) {
                if (!value) return ''
                return value.toString().toUpperCase()
            },
            ln2br: function (value) {
                if (!value) return ''
                return value.toString().replace("\n", "<br>")
            },

        },
        props: {
        },
        methods: {
            excludeChange: function (id) {
                console.log(id)
                if (this.excludeGCId[id]) {
                    this.excludeGCId[id] = false
                } else {
                    this.excludeGCId[id] = true
                }
                this.excludeGCId = { ...this.excludeGCId }
                let excludeList = []
                for (const [key, value] of Object.entries(this.excludeGCId)) {
                    if (value) {
                        excludeList.push(key)
                    }
                }
                let excludeStr = excludeList.join(";")
                if (excludeStr == "") {
                    excludeStr = null
                }
                axios.get(this.$ipService + "/api/users/" + this.currentUser.id + "/setpref/suspended/" + excludeStr).then(response => {
                    this.$store.commit("setUser", response.data)
                })
            },
            monitoringChange: function (evt) {

                if (this.monitoring == this.oldmonitoring) {
                    return
                }
                this.oldmonitoring = this.monitoring
                let newValue = "0"
                if (evt) {
                    newValue = "1"
                }
                axios.get(this.$ipService + "/api/users/" + this.currentUser.id + "/setpref/surveille_type/" + newValue).then(response => {
                    this.$store.commit("setUser", response.data)
                })
            },
            monitoredZonesChange: function (id, evt) {
                let zones = []
                this.monitoredGCId[id] = evt
                for (const [key, value] of Object.entries(this.monitoredGCId)) {
                    if (value) {
                        zones.push(key)
                    }
                }
                let strZones = zones.join(";")
                if (strZones == this.oldmonitoredGCId) {
                    return
                }
                this.oldmonitoredGCId = strZones
                if (strZones == "") {
                    strZones = null
                }
                if (strZones != null) {
                    this.monitoredGC = strZones.split(";")
                } else {
                    this.monitoredGC = []
                }
                axios.get(this.$ipService + "/api/users/" + this.currentUser.id + "/setpref/monitored_zone/" + strZones).then(response => {
                    this.$store.commit("setUser", response.data)
                })
            },
            monitoredTypesChange: function (id, evt) {
                this.monitoredTPId[id] = evt
                let types = []
                for (const [key, value] of Object.entries(this.monitoredTPId)) {
                    if (value) {
                        types.push(key)
                    }
                }
                let strTypes = types.join(";")
                if (strTypes == this.oldmonitoredTPId) {
                    return
                }
                this.oldmonitoredTPId = strTypes
                if (strTypes == "") {
                    strTypes = null
                }
                axios.get(this.$ipService + "/api/users/" + this.currentUser.id + "/setpref/monitored_type/" + strTypes).then(response => {
                    this.$store.commit("setUser", response.data)
                })
            },
            addGroupProduct: function (group, e) {
                e.preventDefault()
                this.$store.commit("addTabGroup", { id: group.id, name: group.label, type: "group", group: group })
                if (this.currentDetail == "") {
                    this.$store.commit("setCurrentDetail", group.label)
                }
            },
        },
        computed: {
            totalRows: function () {
                if (this.groupProducts == null) {
                    return 0
                }
                return this.groupsProducts.length
            },
            groupsProducts: function () {
                if (this.groupProducts == null) {
                    return [];
                }
                if (this.monitoring) {
                    let result = [];
                    this.groupProducts.forEach(gp => {
                        this.groupCountries.forEach(
                            gc => {
                                if (this.modeview == 1 && this.excludeGCId[gp.id]) {
                                    return
                                }
                                if (this.modeview == 2 && !this.excludeGCId[gp.id]) {
                                    return
                                }
                                if (this.filter != null && this.filter.length > 0 && gp.label.toLowerCase().indexOf(this.filter.toLowerCase()) < 0) {
                                    let containProduct = false;
                                    if (gp.products != undefined && gp.products.length > 0) {
                                        for (let i = 0; i < gp.products.length && !containProduct; i++) {
                                            containProduct = (gp.products[i].code.toLowerCase().indexOf(this.filter.toLowerCase()) >= 0);
                                        }
                                    }
                                    if (!containProduct) return;
                                }
                                if (this.monitoredGC.indexOf(""+gc.id) > -1 && gc.groupProductsIds.indexOf(gp.id) > -1) {
                                    
                                    result.push(gp)
                                }
                            }
                        )

                    })

                    return result;
                }
                if (this.filter != null && this.filter.length > 0 || this.modeview != 0) {
                    let result = [];
                    this.groupProducts.forEach(gp => {
                        if (this.modeview == 1 && this.excludeGCId[gp.id]) {
                            return
                        }
                        if (this.modeview == 2 && !this.excludeGCId[gp.id]) {
                            return
                        }
                        if (this.filter != null && this.filter.length > 0 && gp.label.toLowerCase().indexOf(this.filter.toLowerCase()) < 0) {
                            let containProduct = false;
                            if (gp.products != undefined && gp.products.length > 0) {
                                for (let i = 0; i < gp.products.length && !containProduct; i++) {
                                    containProduct = (gp.products[i].code.toLowerCase().indexOf(this.filter.toLowerCase()) >= 0);
                                }
                            }
                            if (!containProduct) return;
                        }
                        result.push(gp)
                    })
                    return result;
                }
                return this.groupProducts;
            },
            ...mapState(["webSites", "groupDurations", "countries", "groupCountries", "groupLevels", "groupTypes", "currentDetail", "currentUser"])
        },
        mounted: function () {
            this.monitoredGCId = {}
            this.monitoredTPId = {}
            this.monitoredGC = []
            this.excludeGCId = []
            this.groupCountries.forEach(gCountries => {
                this.monitoredGCId[gCountries.id] = false
                this.excludeGCId[gCountries.id] = false
            })
            this.groupTypes.forEach(gTypes => {
                this.monitoredTPId[gTypes.id] = false
            })
            this.currentUser.preferences.forEach(pref => {
                if (pref.value == null) return;
                if (pref.key == "surveille_type") {
                    this.oldmonitoring = (pref.value != "0")
                    this.monitoring = (pref.value != "0")
                }
                if (pref.key == "monitored_zone") {
                    this.oldmonitoredGCId = pref.value
                    let ids = pref.value.split(";")
                    for (let j = 0; j < ids.length; j++) {
                        if (ids[j] == "") continue;
                        this.monitoredGCId[ids[j]] = true
                        this.monitoredGC.push(ids[j])
                    }
                }
                if (pref.key == "monitored_type") {
                    this.oldmonitoredTPId = pref.value
                    let ids = pref.value.split(";")
                    for (let j = 0; j < ids.length; j++) {
                        if (ids[j] == "") continue;
                        this.monitoredTPId[ids[j]] = true
                    }
                }
                if (pref.key == "suspended") {
                    this.oldexcludeGCId = pref.value
                    let ids = pref.value.split(";")
                    for (let j = 0; j < ids.length; j++) {
                        if (ids[j] == "") continue;
                        this.excludeGCId[ids[j]] = true
                    }

                }
            })
            if (this.groupProducts != null) {
                return
            }
            this.isLoading = true
            let groupProducts = []
            axios.get(this.$ipService + "/api/groupproducts/WithProducts").then(response => {
                
                response.data.forEach(groupProd => {

                    let webSites = {}
                    this.webSites.forEach(website => {
                        webSites[website.adapterId] = { id: website.id, name: website.name, adapterId: website.adapterId, products: [] }
                    })
                    groupProd.products.forEach(product => {
                        webSites[product.adapterId].products.push(product.code)
                    })
                    groupProd.adapters = Object.values(webSites)
                    groupProducts.push(groupProd)
                })
                this.groupProducts = groupProducts
                this.isLoading = false
            })
        }
    };
</script>

<style scoped>
    .icon-span {
        margin: 5px;
    }
    .clickable {
        cursor: pointer
    }
</style>
