<template>
    <div>
        <b-card title="Group de produit" v-if="action=='list'">
            <b-button pill size="sm" class="mb-2" variant="success" @click="createGProduct">
                <b-icon icon="plus-circle" aria-hidden="true"></b-icon>&nbsp;Ajouter
            </b-button>
            <b-input-group size="sm">
                <b-form-input id="filter-input"
                              v-model="filterValue"
                              placeholder="Groupe ou code produit"></b-form-input>

                <b-input-group-append>
                    <b-button :disabled="!filterValue" @click="filter = filterValue">Filtrer</b-button>
                    <b-button :disabled="!filter" @click="filter = ''; filterValue = ''">Clear</b-button>
                </b-input-group-append>
            </b-input-group>
            <b-pagination v-model="currentPage"
                          :total-rows="totalRows"
                          :per-page="perPage"
                          aria-controls="gProductSort" :busy="groupProductLoading"></b-pagination>
            <b-table :items="gProductSort" :fields="gProductFields" head-variant="light" :per-page="perPage"
                     :current-page="currentPage"
                     :filter="filter"
                     :filter-included-fields="filterOn"
                     small
                     @filtered="onFiltered" :busy="groupProductLoading">
                <template #cell(label)="data">
                    {{ data.value }}
                </template>
                <template #cell(webSites)="data">
                    <div v-for="website in webSites" :key="website.id">{{website.name|upper}} : {{data.value[website.id - 1].groupproducts|producGroupToLine}}</div>
                </template>
                <template #cell(id)="data">
                    <b-button size="sm" class="mb-2" @click="editGProduct(data.value)">
                        <b-icon icon="gear-fill" aria-hidden="true"></b-icon>
                    </b-button>
                    <b-button size="sm" class="mb-2" @click="deleteGProduct(data.value, data.item.label)">
                        <b-icon icon="trash" aria-hidden="true"></b-icon>
                    </b-button>
                </template>
                <template #table-busy>
                    <div class="text-center text-danger my-2">
                        <b-spinner class="align-middle"></b-spinner>
                        <strong>Loading...</strong>
                    </div>
                </template>
            </b-table>
        </b-card>
        <EditGroupProduct v-if="action=='edit'" :editId="editId" v-on:reset="resetForm"></EditGroupProduct>
        <CreateGroupProduct v-if="action=='create'" :editId="editId" v-on:reset="resetForm"></CreateGroupProduct>
    </div>
</template>

<script>
    import { mapState } from "vuex"
    import EditGroupProduct from './EditGroupProduct.vue'
    import CreateGroupProduct from './CreateGroupProduct.vue'
    import axios from 'axios'

    export default {
        name: 'GroupProduct',
        components: {
            EditGroupProduct,
            CreateGroupProduct
        },
        data() {
            return {
                action: "list",
                editId: 0,
                perPage: 5,
                currentPage: 1,
                countRows: -1,
                filterValue: null,
                filter: null,
                filterOn: [],
                gProductFields: [
                    {
                        key: 'label',
                        label: 'Groupe de produit'
                    },
                    {
                        key: 'webSites',
                        label: 'Groups associés'
                    },
                    {
                        key: 'id',
                        label: 'Action'
                    }]
            }
        },
        filters: {
            capitalize: function (value) {
                if (!value) return ''
                value = value.toString().toLowerCase()

                return value.charAt(0).toUpperCase() + value.slice(1)
            },
            upper: function (value) {
                if (!value) return ''
                return value.toString().toUpperCase()
            },
            ln2br: function (value) {
                if (!value) return ''
                return value.toString().replace("\n", "<br>")
            },
            producGroupToLine: function (value) {
                if (value.length == 0) return ''
                let arrName = []
                value.forEach(vA => { arrName.push(vA.code) });
                arrName.sort((a, b) => {
                    return a.localeCompare(b);
                })
                return arrName.join(", ")
            },
        },
        props: {
        },
        methods: {
            deleteGProduct: function (id, name) {
                let self = this
                if (confirm("voulez-vous vraiment supprimer le groupe de produit " + name + " ? ")) {
                    axios.delete(this.$ipService + '/api/GroupProducts/' + id
                    ).then(function () {
                        self.$store.dispatch('loadGroupcountries')
                        self.$store.dispatch('loadCountries')
                        self.$store.dispatch('loadCountriesActives')
                        self.$store.dispatch('loadGrouptypes')
                        self.$store.dispatch('loadGrouplevels')
                        self.$store.dispatch('loadWebsites')
                        self.$store.dispatch('loadGroupdurations')
                        self.$store.dispatch('loadGroupproducts')
                        self.$store.dispatch('loadGroupsOrProducts')
                    }).catch(function (error) {
                        console.log(error)
                    }).finally(() => {

                    })
                }
            },
            editGProduct: function (id) {
                this.editId = id
                this.action = 'edit'
            },
            createGProduct: function () {
                this.action = 'create'
            },
            resetForm: function () {
                this.action = 'list'
            },
            onFiltered: function(filteredItems) {
                // Trigger pagination to update the number of buttons/pages due to filtering
                if (this.groupProducts == undefined || this.groupProducts == null) {
                    this.countRows = -1
                    this.currentPage = 1
                    return 
                }
                this.countRows = filteredItems.length
                this.currentPage = 1
            }
        },
        computed: {
            totalRows: function () {
                if (this.countRows < 0) {
                    if (this.groupProducts == undefined || this.groupProducts == null) {
                        return 0
                    }
                    return this.groupProducts.length
                }
                return this.countRows
            },
            gProductSort: function () {
                if (this.groupProducts == undefined) {
                    return []
                }
                let arrGProducts = this.groupProducts
                arrGProducts.sort((a, b) => {
                    return a.label.localeCompare(b.label);
                })
                arrGProducts.forEach(gproduct => {
                    gproduct.webSites = {}
                    let webSites = {}
                    this.webSites.forEach(website => {
                        webSites[website.adapterId] = { id: website.adapterId, name: website.name, groupproducts: []}
                    })
                    gproduct.products.sort((a, b) => {
                        return a.code.localeCompare(b.label);
                    })
                    gproduct.products.forEach(productGroup => {
                        webSites[productGroup.adapterId].groupproducts.push(productGroup)
                    })
                    gproduct.webSites = Object.values(webSites)

                })
                return arrGProducts;
            },
            ...mapState(["groupEdit", "webSites", "groupDurations", "countries", "groupCountries", "groupLevels", "groupTypes", "groupProducts", "currentDetail", "currentUser", "groupProductLoading"])
        },
        watch: {
            groupEdit(newValue) {
                if (newValue > 0) {
                    this.action = 'edit'
                    this.editId = this.groupEdit
                    this.$store.commit("setGroupEdit", 0)
                }
            }
        },
        mounted: function () {
            if (this.groupEdit > 0) {
                this.action = 'edit'
                this.editId = this.groupEdit
                this.$store.commit("setGroupEdit", 0)
            }
            
        }
    };
</script>

<style scoped>
    .icon-span {
        margin: 5px;
    }
    .clickable {
        cursor: pointer
    }
</style>
